// @ts-ignore
import React from 'react';
import {withRouter} from "react-router-dom";
import './index.less';
import { setHeader } from "@utils";


@withRouter
class Index extends React.Component {
    constructor(props){
        super(props)
        setHeader({
            title:'车联网基础设施监测平台'
        })
    }
    componentDidMount(){
    }
    render() {
        return <div className="App">
            <div className="error">
                <i/>
                <div className="">呃...出现了点状况...</div>
            </div>
        </div>
    }
}

export default Index
