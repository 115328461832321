import React from 'react';
import { Button } from 'antd-mobile'
import './index.less'
export default (props) => {
  if (props.error) {
    return <div className="mt60 tc">遇到错误! <br /><Button type="ghost" className="mlr40 mt40" onClick={props.retry}>重试</Button></div>;
  } else if (props.timedOut) {
    return <div className="mt60 tc">网络较慢，请重试 <br /><Button type="ghost" className="mlr40 mt40" onClick={props.retry}>重试</Button></div>;
  } else if (props.pastDelay) {
    return <div className={'loading'}>
      <i></i>
    </div>;
  } else {
    return null
  }
}
