import React, { createContext, useReducer } from "react";

const initialState = {
  active: false,
  username: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setUsername":
      return {
        ...state,
        username: action.value
      };

    default:
      return state;
  }
};

export const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};
