import axios from "axios";
import config from "@src/config";
import { COOKIES } from "./constants";

export const setHeader = (ext = {}) => {
  window.appback = function () {
    window.history.back();
  };
  window.SandboxNative &&
    window.SandboxNative.setHeader &&
    window.SandboxNative.setHeader(
      JSON.stringify({
        data: {
          title: "",
          leftIcon: "../imgs/r_left.png",
          leftText: "上一页",
          leftCallback: "appback",
          // "rightIcon":"xxx",
          // "rightText":"xxx",
          // "rightCallback":"xxx"
          ...ext,
        },
      })
    );
};
export const ajax = async (
  method,
  url = "",
  params = {},
  successCallback,
  errorCallback,
  ext = {},
  extConfig = {}
) => {
  let baseUrl = config.baseURL;
  if (!!ext.baseURL) {
    baseUrl = ext.baseURL;
  }
  const requestUrl = baseUrl + url;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  
  const token = getCookie(COOKIES.TOKEN);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    let res = null;
    if (method === "get") {
      res = await axios[method](requestUrl, { params, ...extConfig });
    } else {
      res = await axios[method](requestUrl, params, extConfig);
    }
    if (res.data.errorCode === "reLogin") {
      window.location.href = window.location.origin + "/#/login";
      window.location.reload();
    }
    if (res.data.errorCode === "success") {
      console.log(res.data);
      successCallback && successCallback(res);
      return res.data;
    } else {
      console.log(res);
      errorCallback && errorCallback(res);
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const post = function (url, params, successCallback, errorCallback) {
  return ajax("post", ...arguments);
};
export const get = function (url, params, successCallback, errorCallback) {
  return ajax("get", ...arguments);
};

export const getCookie = function (name) {
  let arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
};

export const setCookie = function (name, value) {
  let Days = 30;
  let exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
};

export const delCookie = function (name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

export const getUsefulProvince = async () => {
  let res = await post("admin/rsu/provincialSummary");
  return res;
};

//汽车服务数量
export const getCarAmount = async (region_id) => {
  let res = await post("admin/rsu/bsmNum", { region_id });
  return res;
};

//RSU数量省汇总
export const getRsuAmountByProvince = async (region_id) => {
  let res = await post("admin/rsu/provincialSummary", { region_id });
  return res;
};
//RSU数量市汇总
export const getRsuAmountByCity = async (region_id) => {
  let res = await post("admin/rsu/citySummary", { region_id });
  return res;
};
//状态
export const getStateSummary = async (region_id) => {
  let res = await get("admin/rsu/stateSummary", { region_id });
  if (res.data) {
    return res;
  }
};

// 近五年接入情况
export const getAccessCount = async (region_id) => {
  let res = await post("admin/rsu/accessCount", { region_id });
  if (res.data) {
    return res;
  }
};

//品牌接入情况
export const getRsuBrandInfo = async () => {
  let res = await post("admin/rsu/manufacturerCount");
  if (res.data) {
    return res;
  }
};

//省品牌接入情况
export const getRsuInfoByRegion = async (region_id) => {
  let res = await post("admin/rsu/manufacturerSummaryByRegion", { region_id });
  if (res.data) {
    return res;
  }
};

//map 路口消息处理数量汇总
export const getNodeHandleSummary = async (region_id) => {
  let res = await get("admin/map/nodesSummary", { region_id });
  if (res.data) {
    return res;
  }
};

export const getLightSummary = async (region_id) => {
  let res = await get("admin/spat/intersectionsSummary", { region_id });
  if (res.data) {
    return res;
  }
};

export const getRsuDynamicPart = async (region_id, pageNum, notSetPerPage) => {
  let res = await post(
    `admin/rsu/list?page=${pageNum || 1}${notSetPerPage ? "" : "&per_page=12"}`,
    {
      region_id,
    }
  );
  if (res && res.data) {
    return res;
  }
};
export const getRsuDynamicPartCity = async (region_id, pageNum, per_page) => {
  // let res = await post(`admin/rsu/list?page=1&per_page=${per_page}`,{
  let res = await post(`admin/rsu/list`, {
    region_id,
  });
  if (res && res.data) {
    return res;
  }
};

export const getOpenAddress = async (region_id) => {
  let res = await get("admin/region/query?page=1&per_page=100000", {
    region_id,
  });
  const openAddress = res.data.filter((i) => i.parent_id === region_id);
  return openAddress;
};
export const getAreaAddress = async (region_id) => {
  let res = await post("admin/rsu/address", { region_id });
  if (res.data) {
    return res;
  }
};
export const getSpatList = async (region_id) => {
  //todo 确定url地址
  let res = await post("admin/rsu/spatlist", { region_id });
  // let res = await post('admin/rsu/list?page=1',{region_id})
  if (res.data) {
    return res;
  }
};
export const getAreaRsuInfo = async (rsuEsn) => {
  let res = await post("admin/rsu/info", { rsuEsn });
  if (res.data) {
    return res;
  }
};
