import Loadable from 'react-loadable'
import loading from '../components/loading'
const routes = [{
        path: '/',
        exact: true,
        check_login: false,
        pastDelay:200,
        error:1,
        timedOut:100,
        component: Loadable({
            loader: () =>
                import ('../pages/app/App'),
            loading,
        })
    },
    {
        path: '/province',
        exact: true,
        check_login: true,
        timedOut:1,
        component: Loadable({
            loader: () =>
                import ('../pages/province'),
            loading,
        })
    },
    {
        path: '/city',
        exact: true,
        check_login: true,
        timedOut:1,
        component: Loadable({
            loader: () =>
                import ('../pages/city'),
            loading,
        })
    },
    {
        path: '/area',
        exact: true,
        check_login: true,
        timedOut:1,
        component: Loadable({
            loader: () =>
                import ('../pages/area'),
            loading,
        })
    },
    {
        path: '/login',
        exact: true,
        check_login: false,
        timedOut:1,
        component: Loadable({
            loader: () =>
                import ('../pages/login'),
            loading,
        })
    },
]
export default routes
