import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, HashRouter } from "react-router-dom";
import "@src/index.less";
import "@less/common.scss";
import * as serviceWorker from "./serviceWorker.js";
import routers from "@routers";
import Error from "@pages/error";
import { getCookie, delCookie } from "./utils";
import { COOKIES } from "./utils/constants";
import { refreshPage } from "./utils/router";
import { AppContext, AppProvider } from "./context/index";

const token = getCookie(COOKIES.TOKEN);
if (!token && window.location.href.indexOf("login") === -1) {
  window.location.href = window.location.origin + "/#/login";
}

const logout = () => {
  delCookie(COOKIES.TOKEN);
  delCookie(COOKIES.USERNAME);
  refreshPage();
};

function RenderPersonal() {
  const [state] = useContext(AppContext);
  const username = getCookie(COOKIES.USERNAME);

  return (
    <span className={"login-out"}>
      {state.username || username ? ( // loggedIn
        <span>
          <span className={"username"}>{state.username || username}</span>
          <span className={"out"} onClick={logout}>
            退出
          </span>
        </span>
      ) : ( // not loggedIn
        <span />
      )}
    </span>
  );
}

function BackHome () {
  return (
    <a href="/" className="back-home">返回首页</a>
  )
}

ReactDOM.render(
  <div>
    <AppProvider>
      <BackHome />
      <RenderPersonal />

      <HashRouter basename="/">
        <Switch>
          {routers.map((router, index) => (
            <Route {...router} key={index} />
          ))}
          {/* <Route path="*" component={Error} /> */}
        </Switch>
      </HashRouter>
    </AppProvider>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// if ("addEventListener" in document) {
//   document.addEventListener("DOMContentLoaded", function() {
//     // 参数可以是任意的dom元素，如果写document.body，说明会将document.body下面的所的元素都绑定fastclick
//     fastclick.attach(document.body);
//   }, false);
// }
