console.log(process.env)
let config = null
if(process.env.NODE_ENV === "production"){
    config = {
        // baseURL : 'http://v2x-admin.v2x-cloud.cn:12678/',
        baseURL : '/',
        authorization : '5ef5919c67907058c503fbb25f62ea806cf60'
    }
}else{
    config = {
        baseURL : 'http://localhost:3000/',
        authorization : '5ef5919c67907058c503fbb25effefb97e9fd'
    }
}
export default config
